/**
 * @param {Object} color - Color object containing rgba values
 * @param {Object} color.rgb - RGBA Color Object
 * @param {number} color.rgb.r = Red Color Value 0 to 255
 * @param {number} color.rgb.g = Green Color Value 0 to 255
 * @param {number} color.rgb.b = Blue Color Value 0 to 255
 * @param {number} color.rgb.a = Alpha Opacity Value 0 to 100
 */


const getRGBA = (color:IColorProps) => {
  if (!color) return null
  return `rgba(${color.rgb?.r},${color.rgb?.g},${color.rgb?.b},${color.rgb?.a})`
}

export default getRGBA